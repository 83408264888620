import { useState } from 'react';
import '../css/signup.css';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const handleEmailInputChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Prepare the data to be sent in the POST request
    const date = new Date();
    const formData = {
      email,
      date,
    };

    try {
      // Make the POST request using fetch
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert formData to JSON
      });

      const result = await res.json();
      console.log(result); // Store the server response in state
      setEmail('');
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };

  return (
    <div id="sign-up" className="sign-up-container">
      <h1>All Aboard!</h1>
      <p>Sign up to stay up-to-date on our alpha release!</p>

      <div className="form-floating mb-3">
        <input
          type="email"
          id="email"
          className="form-control"
          placeholder="Email"
          aria-label="Email"
          value={email}
          required
          onChange={handleEmailInputChange}
        ></input>
        <label htmlFor="email">Email</label>
      </div>

      <div style={{ display: 'flex', gap: '1em' }}>
        <input type="checkbox" name="consent-checkbox" id="consent-checkbox" />
        <label htmlFor="consent-checkbox">I agree to receive emails for important news.</label>
      </div>
      <button className="btn btn-primary-lg title-button" onClick={handleSubmit}>
        Sign Up!
      </button>
    </div>
  );
}
