import '../../css/header.css';

export default function Header() {
  return (
    <div className="header-container">
      <button
        className="btn btn-lg"
        style={{
          fontVariantCaps: 'all-small-caps',
        }}
      >
        Showboat
      </button>
      <div>
        <button className="btn btn-lg">
          <i className="bi bi-instagram" />
        </button>

        <button className="btn btn-lg">
          <i className="bi bi-twitter-x" />
        </button>
      </div>
    </div>
  );
}
